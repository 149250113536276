import React, { useCallback, useContext, useMemo } from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider as ApProvider, concat, HttpLink, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { useFlag } from '@unleash/proxy-client-react'
import { GraphQLError } from 'graphql'
import { JwtPayload, jwtDecode } from 'jwt-decode'
import { isNil } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { EPIC_4335_BUG_5895, OPERATIONAL_401_UNAUTHORIZED } from '@mth/constants'
import { MthRoute, QUERY_PARAMETERS } from '@mth/enums'
import { logoutUser, refreshToken } from '@mth/services/jwt-session/jwt-session.service'
import { AuthContext } from '../AuthProvider/AuthContext'
import { UserContext } from '../UserContext/UserProvider'

const MILISECONDS = 1000
const isTokenExpired = (token: string) => {
  try {
    const decodedToken = jwtDecode<JwtPayload>(token)
    const currentTime = Math.floor(Date.now() / MILISECONDS)
    return decodedToken.exp && decodedToken.exp < currentTime
  } catch (error) {
    console.error('there was an error validating token expiration')
    console.error(error)
    return true
  }
}

type ApolloProviderProps = {
  children: React.ReactNode
}

export const ApolloProvider: React.FC<ApolloProviderProps> = ({ children }) => {
  const { credentials, signOut, setCredentials } = useContext(AuthContext)
  const history = useHistory()
  const { setMe } = useContext(UserContext)
  const location = useLocation()
  const currentPath = location.pathname
  const epic4335Bug5895 = useFlag(EPIC_4335_BUG_5895)
  const operational401unauthorized = useFlag(OPERATIONAL_401_UNAUTHORIZED)

  const logout = async (noExpiration = false) => {
    await logoutUser()
    setMe(null)
    signOut()

    history.push({
      pathname: MthRoute.DASHBOARD,
      ...(noExpiration
        ? {}
        : { search: new URLSearchParams({ [QUERY_PARAMETERS.EXPIRATION]: currentPath }).toString() }),
    })
  }

  const logoutMemoized = useCallback(logout, [setMe, signOut, history, currentPath])

  const getToken = () => {
    if (typeof credentials === 'string') return credentials
    if (!isNil(localStorage.getItem('masquerade'))) return localStorage.getItem('masquerade')
    return localStorage.getItem('JWT')
  }

  const getTokenMemoized = useCallback(getToken, [credentials])

  const authLink = setContext(async (_, { headers }) => {
    let token = getToken()
    if (token && isTokenExpired(token)) {
      token = await refreshToken(setCredentials, logout)
      if (!token || isTokenExpired(token)) {
        await logout()
        console.warn('Token still expired after refresh')
      }
    }
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const authLinkMemoized = useMemo(() => authLink, [setCredentials, logoutMemoized, getTokenMemoized])

  const httpLink = new HttpLink({ uri: import.meta.env.VITE_PUBLIC_API_URL })

  const httpLinkMemoized = useMemo(() => httpLink, [])

  const createApolloLink = () =>
    ApolloLink.from([
      onError(({ graphQLErrors }) => {
        if (graphQLErrors) {
          for (const err of graphQLErrors as GraphQLError[]) {
            if (err.extensions?.exception?.status === 401) {
              // prevent to show the expiration if is calling me
              // it prevent to show the expiration message when the users logout
              // usually “me” is called with other queries and those queries are where the 401 error will be caught.
              const isCallingMe = err.extensions?.exception?.path?.includes('me') || false
              void logout(isCallingMe)
            }
          }
        }
      }),
      authLink,
      httpLink,
    ])

  const client = new ApolloClient({
    cache: new InMemoryCache({ addTypename: false }),
    link: operational401unauthorized ? createApolloLink() : concat(authLink, httpLink),
  })

  const clientMemoized = useMemo(() => client, [authLinkMemoized, httpLinkMemoized])

  return <ApProvider client={epic4335Bug5895 ? clientMemoized : client}>{children}</ApProvider>
}
